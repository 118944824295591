@import url("./_reset.css");
@import url("./_fonts.css");

:root {
  --color-body-background: #050505;
  --color-body-font: #ddd;
  --color-body-font-darker: #aaa;
  --color-border: #333;
  --color-document-background: #111;
  --color-inline-code-background: #0080ff30;
  --color-link-active: #e6192c;
  --color-link-decoration: var(--color-link-active);
  --color-text-decoration-highlight: var(--color-body-font);
  --font-family-body: "Noto Sans", sans-serif;
  --font-family-monospace: monospace;
  --height-scrollbar-horizontal: 8px;
  --width-scrollbar-vertical: 8px;
  --z-index-documentation-aside: 1;
}

@media screen and (max-width: 1023px) {
  :root {
    --font-size-base: 18px;
    --font-size-code: 16px;
    --font-size-code-smaller: 14px;
    --font-size-smaller: 16px;
  }
}
@media screen and (min-width: 1024px) {
  :root {
    --font-size-base: 22px;
    --font-size-code: 18px;
    --font-size-code-smaller: 18px;
    --font-size-smaller: 20px;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
  height: var(--height-scrollbar-horizontal);
  width: var(--width-scrollbar-vertical);
}

::-webkit-scrollbar-thumb {
  background-color: var(--color-border);
}

a {
  transition:
    filter 0.1s ease,
    opacity 0.1s ease;
}

a:active,
.turbo-clicked {
  filter: grayscale(0.5);
  transform: translate3d(1px, 1px, 0);
}

.turbo-clicked {
  opacity: 0.8;
  pointer-events: none;
}

/**
 * Body content
 */

.body-content {
  display: grid;
  grid-template-rows: 1fr auto;
  min-height: 100vh;
}

/**
 * Heading permalink
 */

.heading-permalink {
  display: inline-block;
  color: var(--color-link-active);
  font-size: 1em;
  padding-right: 10px;
  text-decoration: none;
  user-select: none;

  @media screen and (min-width: 1480px) {
    left: calc(-1ch - 10px);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.heading-permalink:target {
  outline: none;
}

/**
 * Primary footer
 */

.primary-footer {
  align-items: center;
  background-color: var(--color-primary-footer-background);
  border-top: 1px solid var(--color-border);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-smaller);
  padding: 20px;
  row-gap: 80px;
}

.primary-footer__copyright,
.primary-footer__link-groups {
  max-width: 1600px;
  width: 100%;
}

.primary-footer__copyright {
  text-align: center;
}

/**
 * Turbo progress bar
 */

.turbo-progress-bar {
  height: 8px;
  background-color: var(--color-link-active);
  position: fixed;
  top: 0;
  width: 100%;
}
